// Variables
/*@import "variables";*/
$bg_color: #ffffff;

$fontfamily:'MElleHK', "PingHei", "PingFang TC", "Microsoft JhengHei", "Microsoft YaHei", Arial, Helvetica, sans-serif;

$grid-gutter-width: 20px;

// Bootstrap
@import "bootstrap/scss/functions";
@import "bootstrap/scss/bootstrap";

@import "global";

@font-face {font-family: "MElleHK";
  src: url("../font/096f1f432a938f6dd5410735bbb9ea49.eot"); /* IE9*/
  src: url("../font/096f1f432a938f6dd5410735bbb9ea49.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../font/096f1f432a938f6dd5410735bbb9ea49.woff2") format("woff2"), /* chrome、firefox */
  url("../font/096f1f432a938f6dd5410735bbb9ea49.woff") format("woff"), /* chrome、firefox */
  url("../font/096f1f432a938f6dd5410735bbb9ea49.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("../font/096f1f432a938f6dd5410735bbb9ea49.svg#MElleHK") format("svg"); /* iOS 4.1- */
}

html{
	font-size:16px;
	@include desktop{
		
	}
	@media (max-height: 600px) {
		font-size:12px;
	}
}

body{
	font-family:$fontfamily;
	background:#ffffff;
}

header{
	background:#ffffff;
	padding:20px 0;
}

footer{
	height:150px;
	background:#676eac;
	display:flex;
	align-items:center;
	justify-content:center;
	padding:20px;
	@media (max-height: 750px) {
		height:100px;
	}
	>div{
		width:100%;
	}
}

.center{
	width:1260px;
	max-width:100%;
	padding-left:20px;
	padding-right:20px;
	margin:auto;
	@include tablet{
		padding-left:30px;
		padding-right:30px;
	}
}

#wrapper{
	/*max-width:768px;*/
	min-width:375px;
	max-width:100%;
	min-height:550px;
	margin:auto;
	height:100vh;
	display:flex;
	flex-direction:column;
	overflow:hidden;
	position:relative;
}

#content{
	height:calc(100% - 150px);
	background:url('../../images/bg.jpg') center bottom no-repeat;
	background-size:cover;
	@media (max-height: 750px) {
		height:calc(100% - 100px);
	}
}

.page-btn{
	display:inline-block;
	width:320px;
	max-width:100%;
	@media (max-height: 750px) {
		width:260px;
	}
}

.home-logo{
	margin:9vh 0;
	img{
		width:50%;
	}
}

.popup-frame-container{
	width:320px;
	/*max-width:400px;*/
	margin:5% auto;
	@media (max-height: 600px) {
		width:260px;
	}
	.popup-frame{
		width:100%;
		position:relative;
		background-repeat:no-repeat;
		background-size:cover;
		&.rules{
			padding-top:calc(100% * (1275 / 865));
			background-image:url('../../images/frame_rules_bg.png');
		}
		&.terms{
			padding-top:calc(100% * (1275 / 865));
			background-image:url('../../images/frame_terms_bg.png');
		}
		&.login{
			padding-top:calc(100% * (644 / 865));
			background-image:url('../../images/frame_login_bg.png');
		}
		&.memberpanel{
			padding-top:calc(100% * (1268 / 865));
		}
		&.times-3{
			background-image:url('../../images/frame_3times_bg.png');
		}
		&.times-2{
			background-image:url('../../images/frame_2times_bg.png');
		}
		&.times-1{
			background-image:url('../../images/frame_1times_bg.png');
		}
		&.times-0{
			background-image:url('../../images/frame_complete_bg.png');
		}
		&.instruction{
			padding-top:calc(100% * (1639 / 919));
			background-image:url('../../images/frame_instruction_bg.png');
		}
		.popup-content{
			position:absolute;
			width:100%;
			height:100%;
			left:0;
			top:0;
			padding:20% 10%;
		}

		.green-column{
			position:absolute;
			width:100%;
			bottom:0;
			left:0;
			text-align:center;
			color:#ffffff;
			font-size:20px;
			padding:6% 0;
		}

		.remain-award-ireward{
			font-size:18px;
			font-weight:bold;
			p{
				margin:0;
				line-height:1.2;
			}
		}

		.rulesContainer{
			height:55%;
		}
		.awardsContainer{
			margin:22% 0 0;
		}
		.agentnumberContainer{
			margin:15% 0;
		}
		.userContainer{
			margin:66% 0 0;
		}
		.userLabel{
			width:100%;
			padding-top:calc(100% * (146 / 750));
			position:relative;
			background:url('../../images/label_user_name.png') left top no-repeat;
			background-size:cover;
			font-size:1.2em;
			>div{
				color:#ffffff;
				position:absolute;
				left:0;
				top:0;
				line-height:22px;
				height:100%;
				width:100%;
				padding:5% 10% 5% 22%;
				font-weight:bold;
			}
		}
		.marksLabel{
			width:100%;
			margin:6% 0 0;
			padding-top:calc(100% * (146 / 750));
			position:relative;
			background:url('../../images/label_marks.png') left top no-repeat;
			background-size:cover;
			font-size:1.2em;
			overflow: hidden;
			>div{
				color:#B15340;
				position:absolute;
				left:0;
				top:0;
				line-height:22px;
				height:100%;
				width:100%;
				padding:5% 10% 5% 22%;
				font-weight:bold;
			}
			&:after{
				content:"";
				position:absolute;
				left:-60px;
				top:0;
				width:60px;
				height:100%;
				background:url('../../images/label_lighting.png') left top no-repeat;
				background-size:cover;
				animation-name: shining;
				animation-duration: 3.5s;
				animation-iteration-count: infinite;
				animation-delay: 2s;
				animation-timing-function: ease-in-out;
			}
		}
	}
}

@keyframes shining {
	0% {left:-60px;}
	35% {left:100%;}
	100% {left:100%;}
}

.ui.checkbox label{
	color:#ffffff !important;
	font-size:18px;
	font-weight:bold;
	padding-left:22px;
}

.form-control{
	@include custom-placeholder{
		font-size: 10px;
	}
}

.resolution-block{
	display:none;
	position:fixed;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:url('../../images/resolution_block_bg.png') center center no-repeat #000000;
	background-size:cover;
	z-index:999;
}

#result-content{
	height:100%;
	background:url('../../images/result_bg.jpg') center center no-repeat;
	background-size:cover;
	position:relative;
	&:before{
		content:"";
		position:absolute;
		left:50%;
		top:50%;
		transform:translate(-50%, -50%);
		width:2000px;
		height:2000px;
		background: url('../../images/light_effect.png') center center no-repeat;
		animation-name: lightRotate;
		animation-duration: 5s;
		animation-iteration-count:infinite;
		animation-delay: 0s;
		animation-timing-function:linear;
		opacity:.7;
	}
	&.startAnim{
		#result-ball{
			.ball{
				animation-name: rotate;
				animation-duration: 2.5s;
				animation-iteration-count:1;
				animation-delay: 0s;
				animation-timing-function:ease-out;
				animation-fill-mode: forwards;
			}
			.top{
				animation-name: topOpen;
				animation-duration: 1s;
				animation-iteration-count:1;
				animation-delay: 2.5s;
				animation-timing-function:cubic-bezier(.44,.2,.45,.99);
				animation-fill-mode: forwards;
			}
			.bottom{
				animation-name: bottomOpen;
				animation-duration: 1s;
				animation-iteration-count:1;
				animation-delay: 2.5s;
				animation-timing-function:cubic-bezier(.44,.2,.45,.99);
				animation-fill-mode: forwards;
			}
		}
	}
	.result-overlay{
		background:rgba(255, 255, 255, .7);
		position:absolute;
		width:100%;
		height:100%;
		left:0;
		top:0;
		padding:0 0 100px;
		@include fadeShow;
		&.show{
			.awards-detail{
				opacity:1;
				transform: translateY(0) scale(1);
				.awards-icon, .awards-caption{
					/*transform:translateX(0);*/
				}
			}
			.awards-125{
				transform:translateX(-50%);
			}
		}
		.btn-close{
			position:absolute;
			left:50%;
			bottom:26px;
			width:50px;
			height:50px;
			transform:translateX(-50%);
			background:url('../../images/btn_close.png') center center no-repeat;
			background-size:cover;
		}
		.awards-125{
			position:absolute;
			left:50%;
			transform:translateX(-500px);
			bottom:100px;
			width:120px;
			transition:all .5s 1s cubic-bezier(.12,.73,.32,1.28);
			img{
				max-width:100%;
			}
		}
		.awards-detail{
			text-align: center;
			margin:14% 0 0;
			transform: translateY(37vh) scale(.5);
			opacity:0;
			transition:all .85s cubic-bezier(.12,.73,.32,1.28);
			@media (max-height: 600px) {
				margin:5% 0 0;
			}
			.awards-icon{
				/*transform:translateX(-100%);
				transition:all .5s ease;*/
			}
			.awards-caption{
				/*transform:translateX(100%);
				transition:all .5s ease;*/
				margin:10px 0 0;
			}
		}
	}
	#result-ball{
		position:absolute;
		left:50%;
		top:50%;
		width:150px;
		height:150px;
		transform:translate(-50%, -50%);
		.ball{
			
		}
		.top{
			width:150px;
			padding-top:calc(100% * (226 / 450));
			background-position:center center;
			background-repeat:no-repeat;
			background-size:cover;
			transform-origin:left bottom;
		}
		.bottom{
			width:150px;
			padding-top:calc(100% * (226 / 450));
			background-position:center center;
			background-repeat:no-repeat;
			background-size:cover;
			transform-origin:left top;
		}
		&.color1{
			.top{
				background-image:url('../../images/ball_top.png');
			}
			.bottom{
				background-image:url('../../images/ball_bottom.png');
			}
		}
		&.color2{
			.top{
				background-image:url('../../images/ball_top_1.png');
			}
			.bottom{
				background-image:url('../../images/ball_bottom_1.png');
			}
		}
		&.color3{
			.top{
				background-image:url('../../images/ball_top_2.png');
			}
			.bottom{
				background-image:url('../../images/ball_bottom_2.png');
			}
		}
		&.color4{
			.top{
				background-image:url('../../images/ball_top_1.png');
			}
			.bottom{
				background-image:url('../../images/ball_bottom_1.png');
			}
		}
	}
}

/* 360 x 8 = 2880deg */
@keyframes rotate{
	0% {transform:rotate(0deg);}
	100% {transform:rotate(1355deg);}
}

@keyframes lightRotate{
	0% {transform:translate(-50%, -50%) rotate(0deg);}
	100% {transform:translate(-50%, -50%) rotate(360deg);}
}

@keyframes topOpen{
	0% {transform:rotate(0deg);}
	100% {transform:rotate(-30deg);}
}

@keyframes bottomOpen{
	0% {transform:rotate(0deg);}
	100% {transform:rotate(20deg);}
}

.overlay{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	z-index:99;
	&.login-fail{
		background:url('../../images/overlay_login_fail.png') center center no-repeat;
		background-size: cover;
	}
	&.agreeterms-fail{
		display:none;
		background:url('../../images/overlay_terms_fail.png') center center no-repeat;
		background-size: cover;
	}
	&.finish-game{
		display:none;
		background:url('../../images/overlay_game_finish.png') center center no-repeat;
		background-size: cover;
		text-align: center;
		padding:0 0 100px;
		.overlay-detail{
			text-align: center;
			position:absolute;
			bottom:22%;
			width:100%;
			font-size:3rem;
			font-weight:bold;
			color:#ffffff;
			@include tablet{
				bottom:24%;
			}
			@include desktop{
				font-size:4rem;
			}
		}
		img.icon{
			width:130px;
			margin:0 0 1rem;
		}
		.btn-close{
			position:absolute;
			left:50%;
			bottom:26px;
			width:50px;
			height:50px;
			transform:translateX(-50%);
			background:url('../../images/btn_close_white.png') center center no-repeat;
			background-size:cover;
		}
	}
}

.close-instruction{
	display:inline-block;
	width:60px;
	height:60px;
	background:url('../../images/btn_close_instruction.png') center center no-repeat;
	background-size:cover;
	position:absolute;
	right:-22px;
	top:-12px;
	z-index:99;
}

.instruction-overlay{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:rgba(0, 0, 0, .9);
	display:flex;
	align-items:center;
}

.instruction-swiper{
	height:100%;
	.swiper-slide{
		text-align:center;
		img{
			margin:23% 0 0;
			max-width:100%;
		}
	}
	.swiper-pagination{
		bottom:0;
	}
	.swiper-pagination-bullet{
		width:12px;
		height:12px;
		margin:0 14px !important;
	}
	.swiper-pagination-bullet-active{
		background:#C9295F;
	}
	
}


